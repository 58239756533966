.footer-container {
  margin-top: 16%;
  text-align: center;
  font-family: "Source Code Pro", monospace;
  color: var(--color-green);
}
.footer-context {
  display: flex;
  flex-direction: column;
}

.footer-icons {
  font-size: 18px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-left: auto;
  margin-right: auto;
  color:var(--color-green);
}
.footer-linkedin:hover {
  background-color: rgba(255, 255, 255, 0.5);
}
.footer-mail:hover {
  background-color: rgba(255, 255, 255, 0.5);
}
.footer-gh:hover {
  background-color: rgba(255, 255, 255, 0.5);
}

a:visited {
  text-decoration: none;
  color: var(--color-green);
}
