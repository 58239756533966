body {
  background-size: 30%;
  background: #040720;
  color: white;
  /* background-color: #0a192f; */
  background-image: url("./assets/topography-2.svg");
  background-attachment: fixed;
  font-family: "NTR", sans-serif;
}

/* Layout.jsx */
.layout {
  align-items: center;
  width: auto;
  padding: 0 1rem;
}
a:visited {
  text-decoration: none;
  color: var(--color-green);
}

:root {
  --color-green: #64ffda;
  --color-bg: #040720;
  --color-white: #fff;
  --transition: all 400ms ease;
  --light-gray: #7883a1;
  --color-header: rgb(136, 146, 176);
  --color-text: rgb(113, 124, 152);
  --red: hsl(0, 78%, 62%);
  --cyan: hsl(180, 62%, 55%);
  --orange: hsl(34, 97%, 64%);
  --blue: hsl(212, 86%, 64%);
  --varyDarkBlue: hsl(234, 12%, 34%);
  --grayishBlue: hsl(229, 6%, 66%);
  --veryLightGray: hsl(0, 0%, 98%);
  --weight1: 200;
  --weight2: 400;
  --weight3: 600;
}

html {
  scroll-behavior: smooth;
}

h1 {
  font-weight: 500;
  font-size: 28px;
  /*color: rgba(10, 200, 120, 10.5); */
  color: var(--color-green);
}

.divider {
  border: 0;
  height: 2px;
  background-color: var(--color-green);
  margin-bottom: 1.5rem;
}

/* Experience */
.chrono-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15%;
}

.fade-in {
  opacity: 0;
  /* transform: translateY(10vh); */
  visibility: hidden;
  transition: opacity 3s ease-out;
  will-change: opacity, visibility;
}

.fade-in.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

/* Ball Animation */
.frame {
  position: absolute;
  top: 20%;
  left: 50%;
  width: 400px;
  height: 400px;
  margin-top: -200px;
  margin-left: -200px;
  border-radius: 2px;
  /* box-shadow: 1px 2px 10px 0px rgba(0, 0, 0, 0.3); */
  background: transparent;
  -webkit-filter: contrast(25);
  filter: contrast(25);
}

.center {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.ball {
  position: relative;
  width: 85px;
  height: 85px;
  background: #fff;
  border-radius: 50%;
  -webkit-filter: blur(10px);
  filter: blur(10px);
}

.ball1 {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 50px;
  height: 50px;
  -webkit-transform: rotate(150deg);
  transform: rotate(150deg);
}
.ball1:after {
  position: absolute;
  display: block;
  content: "";
  width: 50px;
  height: 50px;
  background: #fff;
  border-radius: 50%;
  -webkit-transform-origin: 37px 37px;
  transform-origin: 37px 37px;
  -webkit-animation: rotate 2.7s ease-in-out 0.2s infinite;
  animation: rotate 2.7s ease-in-out 0.2s infinite;
  -webkit-filter: blur(5px);
  filter: blur(5px);
}
.ball2 {
  width: 50px;
  height: 50px;
  top: 20px;
  left: 20px;
  position: absolute;
  -webkit-transform: rotate(206deg);
  transform: rotate(206deg);
}
.ball2:after {
  position: absolute;
  display: block;
  content: "";
  width: 50px;
  height: 50px;
  transform-origin: 34px 34px;
  background: #fff;
  border-radius: 50%;
  animation: rotate 2.9s ease-in-out 0.4s infinite;
  -webkit-filter: blur(5px);
  filter: blur(5px);
}

.ball3 {
  width: 50px;
  height: 50px;
  top: 20px;
  left: 20px;
  position: absolute;
  -webkit-transform: rotate(274deg);
  transform: rotate(274deg);
}
.ball3:after {
  background: #fff;
  display: block;
  content: "";
  width: 50px;
  height: 50px;
  border-radius: 50%;
  transform-origin: 31px 31px;
  animation: rotate 3.1s ease-in-out 0.6s infinite;
  -webkit-filter: blur(5px);
  filter: blur(5px);
}

.ball4 {
  width: 50px;
  height: 50px;
  top: 20px;
  left: 20px;
  position: absolute;
  -webkit-transform: rotate(152deg);
  transform: rotate(152deg);
}
.ball4:after {
  background: #fff;
  display: block;
  content: "";
  width: 50px;
  height: 50px;
  border-radius: 50%;
  transform-origin: 28px 28px;
  animation: rotate 3.3s ease-in-out 0.8s infinite;
  -webkit-filter: blur(5px);
  filter: blur(5px);
}

.ball5 {
  width: 50px;
  height: 50px;
  top: 20px;
  left: 20px;
  position: absolute;
  -webkit-transform: rotate(284deg);
  transform: rotate(284deg);
}
.ball5:after {
  background: #fff;
  display: block;
  content: "";
  width: 50px;
  height: 50px;
  border-radius: 50%;
  transform-origin: 25px 25px;
  animation: rotate 3.5s ease-in-out 1s infinite;
  -webkit-filter: blur(5px);
  filter: blur(5px);
}

.ball6 {
  width: 50px;
  height: 50px;
  top: 20px;
  left: 20px;
  position: absolute;
  -webkit-transform: rotate(24deg);
  transform: rotate(24deg);
}
.ball6:after {
  background: #fff;
  display: block;
  content: "";
  width: 50px;
  height: 50px;
  border-radius: 50%;
  transform-origin: 22px 22px;
  animation: rotate 3.7s ease-in-out 1.2s infinite;
  -webkit-filter: blur(5px);
  filter: blur(5px);
}

.ball7 {
  width: 50px;
  height: 50px;
  top: 20px;
  left: 20px;
  position: absolute;
  -webkit-transform: rotate(2deg);
  transform: rotate(2deg);
}
.ball7:after {
  background: #fff;
  display: block;
  content: "";
  width: 50px;
  height: 50px;
  border-radius: 50%;
  transform-origin: 19px 19px;
  animation: rotate 3.9s ease-in-out 1.4s infinite;
  -webkit-filter: blur(5px);
  filter: blur(5px);
}

.ball8 {
  width: 50px;
  height: 50px;
  top: 20px;
  left: 20px;
  position: absolute;
  -webkit-transform: rotate(142deg);
  transform: rotate(142deg);
}
.ball8:after {
  background: #fff;
  display: block;
  content: "";
  width: 50px;
  height: 50px;
  border-radius: 50%;
  transform-origin: 16px 16px;
  animation: rotate 4.1s ease-in-out 1.6s infinite;
  -webkit-filter: blur(5px);
  filter: blur(5px);
}

.point1 {
  width: 8px;
  height: 8px;
  top: 38px;
  left: 38px;
  position: absolute;
  -webkit-transform: rotate(142deg);
  transform: rotate(142deg);
}
.point1:after {
  position: absolute;
  display: block;
  content: "";
  width: 8px;
  height: 8px;
  background: #fff;
  border-radius: 50%;
  -webkit-transform-origin: 58px 58px;
  transform-origin: 58px 58px;
  -webkit-animation: rotate 3.7s ease-in-out 0.2s infinite;
  animation: rotate 3.7s ease-in-out 0.2s infinite;
  -webkit-filter: blur(3px);
  filter: blur(3px);
}

.point2 {
  width: 9px;
  height: 9px;
  top: 38px;
  left: 38px;
  position: absolute;
  -webkit-transform: rotate(25deg);
  transform: rotate(25deg);
}
.point2:after {
  position: absolute;
  display: block;
  content: "";
  width: 9px;
  height: 9px;
  background: #fff;
  border-radius: 50%;
  -webkit-transform-origin: 56px 56px;
  transform-origin: 56px 56px;
  -webkit-animation: rotate 3.9s ease-in-out 0.4s infinite;
  animation: rotate 3.7s ease-in-out 0.2s infinite;
  -webkit-filter: blur(3px);
  filter: blur(3px);
}
.point3 {
  width: 10px;
  height: 10px;
  top: 38px;
  left: 38px;
  position: absolute;
  -webkit-transform: rotate(136deg);
  transform: rotate(136deg);
}
.point3:after {
  position: absolute;
  display: block;
  content: "";
  width: 10px;
  height: 10px;
  background: #fff;
  border-radius: 50%;
  -webkit-transform-origin: 54px 54px;
  transform-origin: 54px 54px;
  -webkit-animation: rotate 4.1 s ease-in-out 0.6s infinite;
  animation: rotate 4.1s ease-in-out 0.6s infinite;
  -webkit-filter: blur(3px);
  filter: blur(3px);
}

.point4 {
  width: 11px;
  height: 11px;
  top: 38px;
  left: 38px;
  position: absolute;
  -webkit-transform: rotate(73deg);
  transform: rotate(73deg);
}
.point4:after {
  position: absolute;
  display: block;
  content: "";
  width: 11px;
  height: 11px;
  background: #fff;
  border-radius: 50%;
  -webkit-transform-origin: 52px 52px;
  transform-origin: 52px 52px;
  -webkit-animation: rotate 4.3 s ease-in-out 0.8s infinite;
  animation: rotate 4.3s ease-in-out 0.8s infinite;
  -webkit-filter: blur(3px);
  filter: blur(3px);
}

.point5 {
  width: 12px;
  height: 12px;
  top: 38px;
  left: 38px;
  position: absolute;
  -webkit-transform: rotate(266deg);
  transform: rotate(266deg);
}
.point5:after {
  position: absolute;
  display: block;
  content: "";
  width: 12px;
  height: 12px;
  background: #fff;
  border-radius: 50%;
  -webkit-transform-origin: 50px 50px;
  transform-origin: 50px 50px;
  -webkit-animation: rotate 4.5 s ease-in-out 1s infinite;
  animation: rotate 4.5s ease-in-out 1s infinite;
  -webkit-filter: blur(3px);
  filter: blur(3px);
}
.point6 {
  width: 13px;
  height: 13px;
  top: 38px;
  left: 38px;
  position: absolute;
  -webkit-transform: rotate(89deg);
  transform: rotate(89deg);
}
.point6:after {
  position: absolute;
  display: block;
  content: "";
  width: 13px;
  height: 13px;
  background: #fff;
  border-radius: 50%;
  -webkit-transform-origin: 48px 48px;
  transform-origin: 48px 48px;
  -webkit-animation: rotate 4.7 s ease-in-out 1.2s infinite;
  animation: rotate 4.7s ease-in-out 1.2s infinite;
  -webkit-filter: blur(3px);
  filter: blur(3px);
}
.point7 {
  width: 14px;
  height: 14px;
  top: 38px;
  left: 38px;
  position: absolute;
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}
.point7:after {
  position: absolute;
  display: block;
  content: "";
  width: 14px;
  height: 14px;
  background: #fff;
  border-radius: 50%;
  -webkit-transform-origin: 46px 46px;
  transform-origin: 46px 46px;
  -webkit-animation: rotate 4.9 s ease-in-out 1.4s infinite;
  animation: rotate 4.9s ease-in-out 1.4s infinite;
  -webkit-filter: blur(3px);
  filter: blur(3px);
}
.point8 {
  width: 15px;
  height: 15px;
  top: 38px;
  left: 38px;
  position: absolute;
  -webkit-transform: rotate(284deg);
  transform: rotate(284deg);
}
.point8:after {
  position: absolute;
  display: block;
  content: "";
  width: 15px;
  height: 15px;
  background: #fff;
  border-radius: 50%;
  -webkit-transform-origin: 44px 44px;
  transform-origin: 44px 44px;
  -webkit-animation: rotate 5.1 s ease-in-out 1.6s infinite;
  animation: rotate 5.1s ease-in-out 1.6s infinite;
  -webkit-filter: blur(3px);
  filter: blur(3px);
}
.point9 {
  width: 16px;
  height: 16px;
  top: 38px;
  left: 38px;
  position: absolute;
  -webkit-transform: rotate(23deg);
  transform: rotate(23deg);
}
.point9:after {
  position: absolute;
  display: block;
  content: "";
  width: 16px;
  height: 16px;
  background: #fff;
  border-radius: 50%;
  -webkit-transform-origin: 42px 42px;
  transform-origin: 42px 42px;
  -webkit-animation: rotate 5.3 s ease-in-out 1.8s infinite;
  animation: rotate 5.3s ease-in-out 1.8 infinite;
  -webkit-filter: blur(3px);
  filter: blur(3px);
}
.point10 {
  width: 18px;
  height: 18px;
  top: 38px;
  left: 38px;
  position: absolute;
  -webkit-transform: rotate(289deg);
  transform: rotate(289deg);
}
.point10:after {
  position: absolute;
  display: block;
  content: "";
  width: 18px;
  height: 18px;
  background: #fff;
  border-radius: 50%;
  -webkit-transform-origin: 40px 40px;
  transform-origin: 40px 40px;
  -webkit-animation: rotate 5.5 s ease-in-out 2s infinite;
  animation: rotate 5.5s ease-in-out 2s infinite;
  -webkit-filter: blur(3px);
  filter: blur(3px);
}
@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0deg) translate3d(0, 0, 0);
    transform: rotate(0deg) translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: rotate(360deg) translate3d(0, 0, 0);
    transform: rotate(360deg) translate3d(0, 0, 0);
  }
}

@keyframes rotate {
  from {
    -webkit-transform: rotate(0deg) translate3d(0, 0, 0);
    transform: rotate(0deg) translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: rotate(360deg) translate3d(0, 0, 0);
    transform: rotate(360deg) translate3d(0, 0, 0);
  }
}
