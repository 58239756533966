.portfolio {
  margin-top: 10%;
}
.portfolio-container {
  margin-top: 5%;
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(150px, 3fr));
  grid-gap: 50px;
}

.card {
  position: relative;
  cursor: default;
  display: flex;
  flex-direction: column;
  padding: 2rem 2rem;
  /* background-color: transparent; */
  background-color: rgba(100, 255, 218, 0.1);
  border-radius: 16px;
  border: 1px solid var(--color-green);
  height: auto;
}

.card-header {
  margin-top: -20px;
  display: flex;
  padding: 1.25em 0 !important;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: none !important;
}

.card-body {
  font-size: 20px;
  font-weight: bold;
}

.card-title {
  font-size: 23px;
  margin-bottom: 15px;
}
.card-tech {
  margin-top: 28px;
  font-size: 16px;
}
.folder {
  font-size: 30px;
  color: var(--color-green);
}

.gh {
  font-size: 30px;
  color: var(--color-green);
  text-decoration: none;
}
a {
  color: var(--color-green);
}
a:visited {
  color: var(--color-green);
}
a:hover {
  color: var(--color-green);
}
/* Large Monitor */
@media only screen and (max-width: 3440px) {
}

/* Standard Monitor */
@media only screen and (max-width: 1024px) {
}

/* Mobile */
@media only screen and (max-width: 768px) {
  .projects-grid {
    display: grid;
    grid-template-columns: repeat(1, minmax(10px, 1fr));
    margin-right: 1.5em;
  }
}
