.about-container {
  display: flex;
  flex-direction: row;
  width: 90%;
  margin: 100px auto;
}
.about-img img {
  /* width: a;
    height: 350px; */
  border-radius: 20%;
}

.tech-stack {
  color: var(--color-green);
  display: flex;
  flex-direction: row;
}
.tech-text {
  padding: 5px;
}

.about-content {
  margin-left: 2rem;
  width: 85%;
}
.about-cards {
  display: flex;
  flex-direction: row;
  gap: 10%;
}
.about-card {
  /* border:1px solid #64ffda; */
  border: 1px solid #64ffda;
  border-radius: 1rem;
  padding: 2rem;
  text-align: center;
  width: 100%;
}
.about-icon {
  font-size: 1.4rem;
  margin-bottom: 0.3rem;
  margin-left: auto;
  margin-right: auto;
  color: var(--color-green);
}
.about-text {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding: 2px;
  margin: 2rem - 2.6rem;
}

.paragraph {
  font-size: 18px;
  margin-left: 1rem;
}
.tech-stack {
  margin-left: 1rem;
  margin-top: 1rem;
}
.tech-stack h1 {
  font-size: 25px;
}
.frontend-content {
  text-align: center;
  margin-bottom: 1.5rem;
}
.backend-content {
  text-align: center;
  margin-bottom: 1.5rem;
}
.db-content {
  text-align: center;
  margin-bottom: 1.5rem;
}
.tools-content {
  text-align: center;
  margin-bottom: 1.5rem;
}

/* Large Monitor */
@media only screen and (max-width: 3440px) {
}

/* Standard Monitor */
@media only screen and (max-width: 1024px) {
}

/* Mobile */
@media only screen and (max-width: 768px) {
  .about-card small {
    font-size: 11.5px;
  }
  .about-img img {
    display: none;
  }
  .about-container {
    width: auto;
    margin: 0px;
    margin-right: 100px;
  }

  .about-card {
    font-size: 11.5px;
    padding: 15px;
  }
  .about-text {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    margin: 0;
    width: 300px;
  }

  .tech-stack {
    padding-top: 15px;
    list-style-type: none;
    display: grid;
    grid-template-columns: repeat(2, minmax(150px, 10px));
    font-size: 13.5px;
  }
  .tech-text {
    padding: 3px;
  }
}
