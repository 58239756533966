.experience {
  margin-top: 20%;
}

.experience-container {
  /* background-color: #212425; */
  width: 90%;
  margin-right: auto;
  margin-left: auto;
  /* border: 1px solid #64ffda; */
}
.job-title {
  font-size: 28px;
  font-weight: bold;
}
.job-company {
  font-size: 28px;
  font-weight: bold;
  color: var(--color-green);
}
.job-duration {
  font-size: 18px;
}
.job-desc {
  padding-top: 24px;
  max-width: 650px;
  font-size: 20px;
}
.job-desc li {
  position: relative;
  padding-left: 30px;
  padding-bottom: 16px;
  font-size: 18px;
  color: white;
}
.MuiButtonBase-root {
  outline: none !important;
  color: white !important;
}
.MuiButtonBase-root:focus {
  color: var(--color-green) !important;
}
.MuiTabs-indicator {
  background: var(--color-green) !important;
}

ul {
  list-style: none;
  margin-left: -10px;
}

/* Large Monitor */
@media only screen and (max-width: 3440px) {
}

/* Standard Monitor */
@media only screen and (max-width: 1024px) {
}

/* Mobile */
@media only screen and (max-width: 768px) {
  .experience {
    margin-bottom: 20%;
  }
  .job-title {
    font-size: 23px;
  }
  .job-company {
    font-size: 23px;
  }
  .job-duration {
    font-size: 21px;
  }

  .job-desc {
    padding-top: 3.5px;
    font-size: 15px;
    max-width: 100%;
  }
  /* .job-desc ul {
    padding: 5px;
    padding-top: 14px;
    max-width: 350px;
    font-size: 24px;
  } */

  .job-desc li {
    position: relative;
    padding-left: 5px;
    padding-bottom: 5px;
    font-size: 16px;
    color: white;
  }

  .makeStyles-root-1 {
    height: unset !important;
    width: unset;
    flex-direction: column;
  }

  .joblist-job-title {
    font-size: 22px;
  }

  .joblist-job-company {
    font-size: 22px;
  }
}
