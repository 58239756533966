.hero-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.hero {
  border-radius: 50%;
  height: 305px;
  width: 325px;
  object-fit: fill;
}

.hero-name {
  margin-top: 1.5rem;
  margin-bottom: 0.25rem;
  font-weight: 600;
  font-size: 3rem;
}
.hero-role {
  margin-bottom: 1rem;
  font-size: 1.5rem;
}
.hero-icons {
  display: flex;
  flex-direction: row;
  font-size: 28px;
  gap: 1rem;
}
.icon-linkedin {
  color: var(--color-green);
  display: inline-block;
  width: 28px;
  height: 28px;
  border-radius: 10px;
  transition: ease-in 0.2s;
}
.icon-linkedin:hover {
  background-color: rgba(255, 255, 255, 0.8);
}
.icon-gh {
  color: var(--color-green);
  display: inline-block;
  border-radius: 15px;
  width: 28px;
  height: 28px;
  transition: ease-in 0.2s;
}
.icon-gh:hover {
  background-color: rgba(255, 255, 255, 0.8);
}
.hero-btn-container {
  padding-top: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}
.hero-btn {
  margin-left: auto;
  margin-right: auto;
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  border-radius: 35px;
  gap: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 1.125rem;
  text-decoration: none;
  /* background: linear-gradient(
    90deg,
    rgba(100, 255, 218, 1) 0%,
    rgba(1, 175, 133, 1) 61%
  ); */
  color: var(--color-green);
  border: 1px solid var(--color-green);
}

.hero-btn:hover {
  color: white;
  cursor: pointer;
  background: linear-gradient(
    90deg,
    rgba(100, 255, 218, 1) 0%,
    rgba(1, 175, 133, 1) 61%
  );
}



/* Large Monitor */
@media only screen and (max-width: 3440px) {

}

/* Standard Monitor */
@media only screen and (max-width: 1024px) {
    
}


/* Mobile */
@media only screen and (max-width: 768px) {
.hero-name {
  font-size: 45px;
}
}